import O18N from 'o18n';
import React from 'react';
import cx from 'classnames';

// import { uvCode } from 'utils';
import globalLocale from 'locale';
import styles from './style.module.scss';

export default function LeftENV({ weather, airQuality, lang }) {
  const t = new O18N(globalLocale, lang);
  
  return (
    <div className={cx(styles.block, styles.leftENV)}>
      <div className={styles.title}>{t.weather}</div>
      <div className={styles.wAndT}>
        <div
          className={cx(styles.weather, styles[`w_${weather.WeatherIcon}`])}></div>
        <div className={styles.temperature}>
          {weather.Temperature}
        </div>
      </div>
      <ul>
        <li>
          <div>{t.businessHours}</div>
          <div>{weather.OpenTime}</div>
        </li>
        <li>
          <div>{t.businessHoursSummer}</div>
          <div>9AM-6PM</div>
        </li>
        <li>
          <div>{t.lastTime}</div>
          <div>{weather.LastAccess}</div>
        </li>
        <li>
          <div>{t.peopleMount}</div>
          <div>
            {!weather.PeopleCount ?
              <div className={cx(styles.img, styles.ppl_x)} />:
              [...Array(3).keys()].map(index =>
                <div key={index}
                  className={cx(styles.img, styles[`ppl_${
                    weather.PeopleCount > index? weather.PeopleCount: 0
                  }`])}
                />
              )
            }
          </div>
        </li>
        <li>
          <div>{t.aq}</div>
          {/* <span>{t[`aq${airQuality.AQ}`]}</span> */}
          <div className={styles[`aq${airQuality.AQ}`]} />
        </li>
        <li>
          <div>{t.uv}</div>
          {/* <span>{t[uvCode(airQuality.UV)]}</span> */}
          <div
            className={styles.sun}
            data-number={airQuality.UV}
          />
        </li>
      </ul>
    </div>
  );
}
