import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import styles from './style.module.scss';

const rangeSize = 5;
const halfSize = ~~(rangeSize / 2);

const Pagination = ({ page, totalPage, base, className, params = {} }) => {
  let query = params.toString().replace(/page=\d/, "");
  query = query ? `&${query}`: '';
  const [from, to] = totalPage <= rangeSize ? [2, totalPage - 1]:
                    page <= halfSize + 1 ? [2, rangeSize]:
                    page >= totalPage - halfSize ? [totalPage - rangeSize + 1, totalPage - 1]:
                    [page - halfSize, page + halfSize];
  
  if( totalPage <= 1 ) return null;
  return (
    <ul className={cx(styles.Pagination, className)}>
      <li key={`page-prev`}>
        {page === 1 ? '前一頁': <Link to={`${base}?page=${page - 1}${query}`}>後一頁</Link>}
      </li>
      <li key={`page-${1}`}>
        {page === 1 ?
          <span className={styles.active}>{page}</span>:
          <Link to={`${base}?page=${1}${query}`}>{1}</Link>
        }
      </li>
      {from > 2 && <span>...</span>}
      {
        [...Array(to - from + 1).keys()].map(v =>
          <li key={`page-${v + from}`}>
            {page === v + from ?
              <span className={styles.active}>{page}</span>:
              <Link to={`${base}?page=${v + from}${query}`}>{v + from}</Link>
            }
          </li>
        )
      }
      {to < totalPage - 1 && <span>...</span>}
      <li key={`page-${totalPage}`}>
        {page === totalPage ?
          <span className={styles.active}>{page}</span>:
          <Link to={`${base}?page=${totalPage}${query}`}>{totalPage}</Link>
        }
      </li>
      <li key={`page-next`}>
        {page === totalPage ? '後一頁': <Link to={`${base}?page=${page + 1}${query}`}>後一頁</Link>}
      </li>
    </ul>
  );
};

export default Pagination;