import O18N from 'o18n';
import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import locale from './locale';
import Globallocale from 'locale';
import styles from './style.module.scss';



import { PATH } from 'consts';

export default function Banner({ url, weather, marquees, showBoard, showHint = false, lang }) {
  const isHide = lang !== 'zh';
  const t = new O18N(Globallocale, lang);
  t.add(locale);

  return (
    <div
      style={{
        backgroundImage: `url(${url})`
      }}
      className={cx(styles.Banner, {
        [styles.isRandom]: !showHint,
      })}
    >
      {
        showHint &&
        <>
          <div className={cx(styles.img, styles.center)} data-text={t.center}></div>
          <div className={cx(styles.img, styles.iceStone)} data-text={t.iceStone}></div>
          <div className={cx(styles.img, styles.birdStone)} data-text={t.birdStone}></div>
          <div className={cx(styles.img, styles.princess)} data-text={t.princess}></div>
          <div className={cx(styles.img, styles.queen)} data-text={t.queen}></div>
          <div className={cx(styles.img, styles.candleStone)} data-text={t.candleStone}></div>
          <div className={cx(styles.img, styles.beeStone)} data-text={t.beeStone}></div>
          <div className={cx(styles.img, styles.fairyShoes)} data-text={t.fairyShoes}></div>
        </>
      }
      <div className={cx("container", styles.blocker)}>
        {showBoard && <>
          {!isHide &&
            <a
              // to={PATH.SERVICE.ENVEDU.HOME}
              href='https://ylgeopark.fonticket.com/'
              target='_blank'
              className={styles.bookEnvEdu}
            >
            </a>
          }
          <div className={styles.miniBoard}>
            <div className={styles.wAndT}>
              <div
                className={cx(styles.weather, styles[`w_${weather.WeatherIcon}`])}></div>
              <div className={styles.temperature}>
                {weather.Temperature}
              </div>
            </div>
            <ul>
              <li>{t.businessHours} <span>{weather.OpenTime}</span> </li>
              <li>{t.businessHoursSummer}<span>9AM-6PM</span></li>
              {/* <li>{t.lastTime} <span>{weather.LastAccess}</span> </li> */}
              <li>{t.peopleMount}
                <span>
                  {!weather.PeopleCount ?
                    <div className={cx(styles.img, styles.ppl_x)} />:
                    [...Array(3).keys()].map(index =>
                      <div key={index}
                        className={cx(styles.img, styles[`ppl_${
                          weather.PeopleCount > index? weather.PeopleCount: 0
                        }`])}
                      />
                    )
                  }
                </span>
              </li>
            </ul>
          </div>
        </>}
        {/* eslint-disable-next-line */}
        {marquees && !!marquees.length && !isHide && (
            // eslint-disable-next-line
            <marquee dangerouslySetInnerHTML={{
              __html: marquees.map(item =>
                `<a href="${item.HyperLink}" target="_blank">${item.Title}${'&nbsp;'.repeat(140)}</a>`
              ).join('')
            }}></marquee>
          )
        }
      </div>
    </div>
  );
}